import ApolloClient from "apollo-client";
import axios from "axios";
//import { SentryLink } from "apollo-link-sentry";

import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloLink } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { useAuthToken } from "./auth";
import { onError } from "apollo-link-error";

const authMiddleware = (authToken) =>
  new ApolloLink((operation, forward) => {
    // add the authorization to the headers

    if (authToken) {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
    }

    return forward(operation);
  });

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_GRAPH_ENDPOINT,
});

httpClient.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${process.env.REACT_APP_MAGIC_KEY}`;

  return config;
});

export const useAppApolloClient = () => {
  const [authToken, setAuthToken] = useAuthToken();

  // const sentryLink = new SentryLink({
  //   setTransaction: true,
  //   setFingerprint: true,
  //   attachBreadcrumbs: {
  //     includeQuery: true,
  //     includeVariables: true,
  //     includeError: true,
  //     includeResponse: true,
  //   },
  //   uri: process.env.REACT_APP_GRAPH_ENDPOINT,
  // });

  const param = {
    link: ApolloLink.from([
      //sentryLink,
      onError(({ graphQLErrors, networkError }) => {
        const getToken = () => {
          httpClient
            .post(process.env.REACT_APP_GRAPH_ENDPOINT, {
              query: `mutation generateSessionToken($personId: String) {
                generateSessionToken(personId: $personId) {
                  person {
                    personId
                    firstName
                    lastName
                    sessionToken
                    sessionTokenExpiry
                    idHash
                  }
    }
  }`,
              variables: {
                personId: localStorage.getItem("hashId"),
              },
            })
            .then(({ data: { data } }) => {
              const {
                generateSessionToken: { person },
              } = data;

              const exp = new Date(person?.sessionTokenExpiry);

              setAuthToken(person?.sessionToken, exp);
            })
            .catch((err) => console.log(err));
        };
        if (graphQLErrors) {
          console.log("Error, Contact support");
        }
        if (networkError) {
          if (
            (networkError.result &&
              networkError.result.error === "Authentication Failed") ||
            (networkError?.result?.error ===
              "Request cannot be completed, transcript access denied." &&
              localStorage.getItem("hashId"))
          ) {
            //console.log(networkError.result.error);
            getToken();
            //return (window.location = '/auth');
          }
        }
      }),
      authMiddleware(
        authToken !== undefined ? authToken : process.env.REACT_APP_MAGIC_KEY
      ),
      new HttpLink({
        uri: process.env.REACT_APP_GRAPH_ENDPOINT,
        credentials: "include",
      }),
    ]),
    cache: new InMemoryCache({}),
  };

  // const client = ApolloClient(param);
  // console.log(client);

  return new ApolloClient(param);
};
