import React from "react";
import "./style.css";
const NotFound = () => {
  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1>404</h1>
          <h2>Page not found</h2>
        </div>
        <a href="https://x2ai.com/" target="__blank">
          Homepage
        </a>
      </div>
    </div>
  );
};

export default NotFound;
