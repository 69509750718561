import React, { Suspense, useEffect } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";

import ReactGA from "react-ga4";
import { withLDProvider } from "launchdarkly-react-client-sdk";

import history from "utils/history";

import Notfound from "pages/404";
import { lazyRetry } from "utils/lazyRetry";
import { useAppApolloClient } from "config/apolloClient";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};

// const Popup = lazy(() => import(/* webpackChunkName: "Popup" */ 'pages/Popup'));
const Popup = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "Popup" */ "pages/Popup"))
);
const Popupv2 = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "Popupv2" */ "pages/Popupv2"))
);
const Embed = React.lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "Embed" */ "pages/Embed"))
);

// const Embed = lazy(() => import(/* webpackChunkName: "Embed" */ 'pages/Embed'));
// const Demo = lazy(() => import(/* webpackChunkName: "Demo" */ 'pages/Demo'));

function App() {
  //console.log(process.env.REACT_APP_GA_ID);
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  // const TRACKING_ID = process.env.REACT_APP_GA_ID; // OUR_TRACKING_ID
  // ReactGA.initialize(TRACKING_ID);
  const apolloClient = useAppApolloClient();

  return (
    <ApolloProvider client={apolloClient}>
      <Router history={history}>
        <Suspense fallback={null}>
          <Switch>
            <Route exact path="/popup/:id/:clientName" component={Popup} />
            <Route
              exact
              path="/popup/v2/:id/:clientName/:botName"
              component={Popupv2}
            />
            <Route exact path="/embed/:id/:clientName" component={Embed} />
            <Notfound />
          </Switch>
        </Suspense>
      </Router>
    </ApolloProvider>
  );
}

export default withLDProvider({
  clientSideID: "6478f67876ee521381eb3e7a",
})(App);
