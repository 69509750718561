import { useApolloClient } from "@apollo/react-hooks";

import { useCookies } from "react-cookie";

const TOKEN_NAME = "sessionToken_cass_web";

// custom hook to handle authToken - we use compositon to decouple the auth system and it's storage
export const useAuthToken = () => {
  const [cookies, setCookie, removeCookie] = useCookies([TOKEN_NAME]);
  const setAuthToken = (authToken, expiry) =>
    setCookie(TOKEN_NAME, authToken, {
      expires: expiry,
      sameSite: "none",
      secure: true,
    });

  const removeAuthToken = () => removeCookie(TOKEN_NAME);
  return [cookies[TOKEN_NAME], setAuthToken, removeAuthToken];
};

export const useLogout = () => {
  const [, , removeAuthToken] = useAuthToken();
  const apolloClient = useApolloClient();

  const logout = async () => {
    await apolloClient.clearStore(); // we remove all information in the store
    removeAuthToken();
  };

  return logout;
};
