import React from "react";
import ReactDOM from "react-dom";

import * as Sentry from "@sentry/react";

import App from "./App";
// import client from 'utils/apolloClient';

import * as serviceWorker from "./serviceWorker";
import "./styles/reset.css";
import "react-tooltip/dist/react-tooltip.css";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_KEY,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["https://web.x2.ai"],
  tracePropagationDelegate: (propagationContext, _) => {
    if (propagationContext.url.includes("https://web.x2.ai")) {
      return propagationContext;
    }
    return null;
  },

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  environment: "production",
  autoSessionTracking: false,
} as Sentry.BrowserOptions);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
